import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import {
  downloadExcel1,
  getPermissionDetails,
  stringLimit,
  stringLimit1,
} from "../../util/fn";
import Pagination from "../../components/Pagination/Pagination";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { BASEURL } from "../../constant/api";
import axios from "axios";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const Feedback = () => {
  const renderTooltip = (props, content) => (
    <Tooltip id="button-tooltip" {...props}>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </Tooltip>
  );
  const { request: requestFeedback, response: responseFeedback } = useRequest();

  const [feedback, setFeedbacks] = useState([]);

  const [columns] = useState([
    { title: "S.No." },
    { title: "Name" },
    { title: "Email" },
    { title: "City" },
    { title: "Query By" },
    { title: "Query From" },
    { title: "Feedback" },
    { title: "Resume" },
    { title: "Vendor Name" },
  ]);
  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [startIndex, setStartIndex] = useState(1);
  let url = `admin/feedback/getAllQuery?per_page=${10}`;
  useEffect(() => {
    url += `&page=${page}`;
    requestFeedback("GET", url);
  }, [page]);

  useEffect(() => {
    if (responseFeedback) {
      if (responseFeedback.status && responseFeedback.data) {
        setFeedbacks(responseFeedback.data);
        setTotalDocuments(responseFeedback?.totalDocument);
      }
    }
  }, [responseFeedback]);

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb title={"Feedbacks"} links={[{ to: "/", name: "Home" }]} />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-6">
                  <h6>{"Feedback"}</h6>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {feedback &&
                      feedback.length > 0 &&
                      feedback.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip({}, item.name)}
                            >
                              <span>{item.name?.substring(0, 50)}</span>
                            </OverlayTrigger>
                          </td>

                          <td>{item.email}</td>
                          <td>{item.cityName}</td>
                          <td>{item.queryBy}</td>

                          <td>{item.queryFrom}</td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip({}, item.feedback)}
                            >
                              <span>{item.feedback?.substring(0, 50)}</span>
                            </OverlayTrigger>
                          </td>
                          <td>{item.resume}</td>
                          <td>{item.vendorName}</td>
                        </tr>
                      ))}
                    {feedback.length == 0 && (
                      <tr>
                        <td colspan="5" style={{ textAlign: "center" }}>
                          {" "}
                          No city attraction has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            feedback.length != totalDocuments &&
            feedback.length != 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={({ selected }) => {
                  setPage(selected + 1);
                }}
                perPage={perPage}
                currentDocLength={feedback.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default Feedback;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import useRequest from "../../../hooks/useRequest";
import { capitalizeFirstLetter } from "../../../util/fn";
import Pagination from "../../Pagination/Pagination";
import { toast } from "react-toastify";
import { upperCase } from "../../../constant/api";
import { OverlayTrigger } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "chart.js";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import { useLocation } from "react-router";

const Rating = ({ rating }) => {
  const fullStars = Math.floor(rating); // Number of full stars
  const halfStar = rating % 1 !== 0; // Check if there's a half star
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Remaining empty stars

  return (
    <span className="ratingss">
      {"★".repeat(fullStars)}
      {halfStar && <span className="half">★</span>}
      {"☆".repeat(emptyStars)}
    </span>
  );
};

const TrackOrder = () => {
  const { id } = useParams();
  const { request: request, response: response } = useRequest();
  const [data, setData] = useState("");

  useEffect(() => {
    request("POST", `admin/order/checkDeliveryStatus`, { deliveryId: id });
  }, []);
  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
      }
    }
  }, [response]);

  console.log("data", data);
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Orders"}
            links={[
              { to: "/dashboard", name: "Home" },
              { to: "/track-order", name: "Vendors" },
            ]}
          />
        </div>
        <div className="col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-8">
                  <b> {data?.driverName}</b>, Your delivery Partner
                </div>
                <div className="col-md-4"> 
                Order Status: <b> {capitalizeFirstLetter(data?.orderStatus)}</b>

                </div>
                <div className="col-md-8 ">
                  Rating: {data?.driverRating}{" "}
                  <Rating rating={parseFloat(data?.driverRating)} />{" "}
                </div>

                <div className="col-md-8 mt-5 ">
                  <h4>
                    OTP:<b>{data?.otp}</b>
                  </h4>
                </div>

                {data?.trackingUrl && (
                  <div className="col-md-8 mt-3 ">
                    Track Location :{" "}
                    <h4>
                      <a
                        href={data?.trackingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Track{" "}
                      </a>
                    </h4>
                  </div>
                )}
                <div className="col-md-6 mt-5 ">
                  <h5>
                    Delivery From: <b>{data?.outletAddress}</b>
                  </h5>
                </div>
                <div className="col-md-6 mt-5 ">
                  <h5>
                    Delivery To: <b>{data?.deliveryAddress}</b>
                  </h5>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackOrder;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import useRequest from "../../../hooks/useRequest";
import { capitalizeFirstLetter } from "../../../util/fn";
import Pagination from "../../Pagination/Pagination";
import { toast } from "react-toastify";
import { upperCase } from "../../../constant/api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaInfoCircle, FaMapMarkedAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
// import { useLocation } from "react-router";

const ViewAll = (props) => {
  const { vendorId } = props?.location?.state;
  const { id: outletId } = props.match.params;
  const { request: request, response: response } = useRequest();

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "User Name" },
    { title: "User Phone Number" },
    { title: "Members" },
    { title: "Date & Time" },
    { title: "Booking For" },
    { title: "Order Status" },
    { title: "Is Verified" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [headerData, setHeaderData] = useState({
    totalOrder: 0,
    cancelOrder: 0,
    completedOrder: 0,
    pendingOrder: 0,
  });
  const [minEndDate, setMinEndDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    document.title = "Orders - Sportyfy";
    request(
      "GET",
      `admin/order/orderHistory?outletId=${outletId}&page=${page}&per_page=${perPage}`
    );
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
        setTotalDocuments(response.totalOrder);
        setHeaderData({
          totalOrder: response.totalOrder ? response.totalOrder : 0,
          cancelOrder: response.cancelOrder ? response.cancelOrder : 0,
          completedOrder: response.completedOrder ? response.completedOrder : 0,
          pendingOrder: response.pendingOrder ? response.pendingOrder : 0,
        });
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    request(
      "GET",
      `admin/order/orderHistory?outletId=${outletId}&page=${
        selected + 1
      }&per_page=${perPage}`
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (e.target.name == "startDate") {
      setStartDate(e.target.value);
      if (e.target.value) {
        setMinEndDate(e.target.value);
      } else {
        setMinEndDate("");
      }
    } else if (e.target.name == "endDate") {
      setEndDate(e.target.value);
      if (e.target.value) {
        setMaxStartDate(e.target.value);
      } else {
        setMaxStartDate("");
      }
    }
  };

  const filterHandler = () => {
    request(
      "GET",
      `admin/order/orderHistory?outletId=${outletId}&page=${page}&per_page=${perPage}&startDate=${startDate}&endDate=${endDate}`
    );
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props?.cancelReason}
    </Tooltip>
  );
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Orders"}
            links={[
              { to: "/dashboard", name: "Home" },
              { to: "/vendors", name: "Vendors" },
              { to: `/vendor/total-outlets/${vendorId}`, name: "Outlets" },
            ]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-3">
                  <h6>{"Orders"}</h6>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="startDate">Start Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      name="startDate"
                      placeholder="Start Date"
                      max={maxStartDate}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="endDate">End Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      name="endDate"
                      placeholder="End Date"
                      min={minEndDate}
                      onChange={handleSearch}
                    />
                  </div>
                </div>

                <div className="col-md-3 my-4">
                  <div className="form-group">
                    <button
                      type="button"
                      onClick={filterHandler}
                      className="btn btn-primary"
                    >
                      {" "}
                      Filter
                    </button>
                  </div>
                </div>
              </div>

              <div className="dh-section mt-4 mx-4">
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                      <div className="ms-card-body media">
                        <div className="media-body">
                          <span className="black-text">
                            <strong>Total Order</strong>
                          </span>
                          <h2>{headerData?.totalOrder}</h2>
                        </div>
                      </div>
                      {/* <LineChart data={this.state.data1} options={options} /> */}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                      {/* <span className="ms-chart-label bg-red">
                                          <i className="material-icons">arrow_downward</i>{" "}
                                          4.5%
                                        </span> */}
                      <div className="ms-card-body media">
                        <div className="media-body">
                          <span className="black-text">
                            <strong>Completed Order</strong>
                          </span>
                          <h2>{headerData?.completedOrder}</h2>
                        </div>
                      </div>
                      {/* <LineChart data={this.state.data2} options={options} /> */}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                      <div className="ms-card-body media">
                        <div className="media-body">
                          <span className="black-text">
                            <strong>Pending Order</strong>
                          </span>
                          <h2>{headerData?.pendingOrder}</h2>
                        </div>
                      </div>
                      {/* <LineChart data={this.state.data3} options={options} /> */}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                      {/* <span className="ms-chart-label bg-red">
                                          <i className="material-icons">arrow_upward</i> 9.5%
                                        </span> */}
                      <div className="ms-card-body media">
                        <div className="media-body">
                          <span className="black-text">
                            <strong>Cancel Order</strong>
                          </span>
                          <h2>{headerData?.cancelOrder}</h2>
                        </div>
                      </div>
                      {/* <LineChart data={this.state.data4} options={options} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                {/* <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>
                            {item.userName
                              ? capitalizeFirstLetter(item.userName)
                              : ""}
                          </td>
                          <td>{item.userMobile}</td>
                          <td>{item.person}</td>
                          <td>{item.date}</td>
                          <td>
                            {item.bookingType
                              ? capitalizeFirstLetter(item.bookingType)
                              : ""}
                          </td>
                          <td>
                            {item.status ? (
                              <span
                                className={
                                  item.status === "decline"
                                    ? "badge badge-danger"
                                    : item.status === "completed"
                                    ? "badge badge-success"
                                    : item.status === "accepted"
                                    ? "badge badge-info"
                                    : item.status === "cancel"
                                    ? "badge badge-danger"
                                    : "badge badge-warning"
                                }
                              >
                                {capitalizeFirstLetter(
                                  item.status == "cancel"
                                    ? "Cancelled"
                                    : item.status == "completed" &&
                                      item.isVerified == "no"
                                    ? "Upcoming"
                                    : item.status
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            {item.isVerified ? (
                              <span
                                className={
                                  item?.isVerified == "yes"
                                    ? "badge badge-success"
                                    : "badge badge-warning"
                                }
                              >
                                {capitalizeFirstLetter(item?.isVerified)}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="8" style={{ textAlign: "center" }}>
                          {" "}
                          No orders has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table> */}

                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">Sr.</th>

                      <th scope="col">Booking Number</th>
                      <th scope="col">User Name</th>
                      <th scope="col">User Phone Number</th>
                      <th scope="col">Members</th>
                      <th scope="col">Table Amount</th>
                      <th scope="col">Menu Amount</th>

                      <th scope="col">Booking Made On</th>
                      <th scope="col">Date & Time</th>
                      {/* <th scope="col">City</th> */}
                      <th scope="col">Booking For</th>
                      <th scope="col">Order Status</th>
                      <th scope="col">Is Verified</th>
                      {/* <th scope="col">Delivered Time</th> */}

                      <th scope="col">View</th>
                      {/* <th scope="col">Price</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data ? (
                      data?.map((order, index) => {
                        return (
                          <tr key={index}>
                            <td scope="row">{data?.indexOf(order) + 1}</td>

                            <td>{order?.bookingNumber}</td>
                            <td>{order.userName}</td>
                            <td>{order.userMobile}</td>
                            <td>{order.person}</td>
                            <td>{order?.tablePaidAmount}</td>
                            <td>{order?.menuPaidAmount}</td>
                            <td>{order?.bookingMadeOn}</td>

                            <td>{order.date}</td>
                            <td>{order.bookingType}</td>

                            <td>
                              <div className="d-flex align-items-center justify-center gap-3">
                                <span
                                  className={
                                    order?.status == "pending"
                                      ? "badge badge-primary"
                                      : order?.status == "Cancel" ||
                                        order?.status == "cancel"
                                      ? "badge badge-danger"
                                      : order?.status == "completed" ||
                                        order?.status == "Completed"
                                      ? "badge badge-completed"
                                      : order?.status == "Upcoming"
                                      ? "badge badge-info"
                                      : order?.status == "accepted"
                                      ? "badge badge-success"
                                      : order?.status == "decline"
                                      ? "badge badge-danger"
                                      : ""
                                  }
                                >
                                  {upperCase(
                                    order?.bookingLabel == "Cancel" ||
                                      order?.bookingLabel == "cancel"
                                      ? "Cancelled"
                                      : order?.bookingLabel
                                  )}
                                </span>

                                {order?.status == "cancel" && (
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip({
                                      cancelReason: order?.cancelReason,
                                    })}
                                  >
                                    <p className="m-0 ml-2">
                                      <FaInfoCircle />
                                    </p>
                                  </OverlayTrigger>
                                )}
                              </div>
                            </td>
                            <td>
                              <span
                                className={
                                  order?.isVerified == "yes"
                                    ? "badge badge-success"
                                    : "badge badge-primary"
                                }
                              >
                                {upperCase(order?.isVerified)}
                              </span>
                            </td>
                            <td>
                              <button
                                type="button"
                                className="ms-btn-icon-outline btn-square btn-info ml-2"
                              >
                                <Link
                                  to={{
                                    pathname: `/order-detail/${order.bookingId}`,
                                  }}
                                >
                                  <i className="flaticon-information mr-0"></i>
                                </Link>
                              </button>

                              {order?.bookingType == "delivery" && (
                                <button
                                  type="button"
                                  className="ms-btn-icon-outline btn-square btn-info ml-2"
                                >
                                  <Link
                                    to={{
                                      pathname: `/track-order/${order.deliveryId}`,
                                    }}
                                  >
                                    <FaMapMarkedAlt />
                                  </Link>
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7">No Orders Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length !== 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;

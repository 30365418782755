import React, { useEffect, useState, useCallback } from 'react'
import { Container, Row, Col, Button } from "react-bootstrap";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import useRequest from '../../hooks/useRequest';
import { useHistory } from 'react-router-dom';
import Right from "../../double-right.svg"
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
// import RewardModal from "./RewardModal"
// import CommisionModal from "./CommisionModal"





function SingleOutlet(props) {

  const { vendorId } = props?.location?.state;
  const { id: outletId } = props?.match?.params;
  const history = useHistory();
  const { request: request, response: response } = useRequest();
  const [outletDetails, setOutletDetails] = useState({});
  const [showCommisionModal, setShowCommisionModal] = useState(false);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [rewardStatus, setRewardStatus] = useState();
  const [outletPhotos, setOutletPhotos] = useState([]);
  const [documentPhotos, setDocumentPhotos] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [docViewerIsOpen, setDocViewerIsOpen] = useState(false);
  const [viewerIsOpen, setViewerIsOpen] = useState(false)


  useEffect(() => {
    if (!outletId) {
      history.push("/dashboard")
    }
    request("POST", `vendor/outlet/getOutletDetail`, { outletId })
  }, [outletId])

  useEffect(() => {
    if (response) {
      if (response.status && response.data) {
        setOutletDetails(response.data)
        setOutletPhotos(response.data?.images ? response?.data?.images : []);
        setDocumentPhotos(response.data?.documents ? response?.data?.documents : [])

      }
    }
  }, [response])


  const handleShowCommisionModal = (commision) => {
    setSliderValue(commision);
    setShowCommisionModal(!showCommisionModal);
  }


  const handleShowRewardModal = (rewardStatus) => {
    setRewardStatus(rewardStatus)
    setShowRewardModal(!showRewardModal);
  }


  const openDocLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setDocViewerIsOpen(true);
  }, []);


  const closeDocLightbox = () => {
    setCurrentImage(0);
    setDocViewerIsOpen(false);
  };

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const renderRewardModal = () => {

    return (
      <Modal
        show={setShowRewardModal}
        onHide={handleShowRewardModal}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reward point partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          5% reward point will be offered as reward loyalty program<br /><br />

          <b>Status:</b> {rewardStatus}
        </Modal.Body>

      </Modal>
    );
  };
  const renderCommisionModal = (outletId) => {
    return (
      <Modal
        show={setShowCommisionModal}
        onHide={handleShowCommisionModal}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Commision</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ marginBottom: "25px" }}>
            <Col md={6}>
              <label htmlFor="foodLicence" className="form-label customLabel">
                Commision
              </label>
              <Slider
                orientation="horizontal"
                min={0}
                max={100}
                value={sliderValue}
                onChange={(val) => setSliderValue(val)}
              />
              {sliderValue}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShowCommisionModal}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    );
  };


  return (
    <div>

      <div className="new-product-class">
        <div className="hotel-detail">
          <Container>
            <Row>
              <Col md={12}>
                <div className="hotel-title">
                  <center>
                    <h2>{outletDetails?.name}</h2>
                  </center>
                </div>
              </Col>
            </Row>
          </Container>
        </div>


        <Row>
          <Col md={6}>
            <div className="about-detail mt-4">
              {/* {localStorage.getItem("vendorType") === "single" && ( */}
              <div className="new mt-4 mb-4">

                {/* {showRewardModal && (
                  <RewardModal
                    handleShowRewardModal={handleShowRewardModal}
                    setShowRewardModal={setShowRewardModal}
                    outletId={outletDetails?.outletId}
                  />
                )}
                {showCommisionModal && (
                  <CommisionModal
                    outletId={outletDetails?.outletId}
                    setShowCommisionModal={setShowCommisionModal}
                    handleShowCommisionModal={handleShowCommisionModal}
                  />
                )} */}
              </div>
              {/* )} */}
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="about">
                      <h3>About Hotel</h3>
                      <p>{outletDetails?.aboutVendor}</p>
                    </div>
                  </Col>

                  {outletDetails?.amenities?.map((item, index) => {
                    return (
                      <Col md={12} key={index}>
                        <div className="cat-list">
                          <h3>{item.name}</h3>
                          <ul>
                            {item.features.map((i, ind) => {
                              return (
                                <li>
                                  <i key={ind}>
                                    {" "}
                                    <img src={Right} />
                                  </i>{" "}
                                  {i}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </Col>
                    );
                  })}
                  <Col md={12}>
                    <div className="cat-list">
                      <div className="d-flex">
                        <h3>Commission Percent</h3>
                      </div>
                      <p>{outletDetails?.commissionPoint} %</p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="cat-list">
                      <div className="d-flex">
                        <h3>Reward Partner Points</h3>
                      </div>
                      <p>{outletDetails?.isReward}</p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="promtion-list">
                      <h3>Discount</h3>
                      {/* <ul >
                      {outletDetails?.outletDiscount?.map((val, i) => (
                        <li key={i}>
                          <div className="list-flex">
                            <b>{val.description}</b> <span className="mx-3"> Discount:<b>{val?.discount}</b> </span> <span className="mx-3">    From {val?.startDate} to {val?.endDate}</span>
                          </div>
                        </li>
                      ))} */}

                      {/* </ul> */}
                      <table
                        className="table  thead-primary w-80"

                      >
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>Discount</th>
                            <th>From Date</th>
                            <th>To Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {outletDetails?.outletDiscount?.map((val, i) => (
                            <tr key={i}>
                              <td>{val.description}</td>
                              <td>{val?.discount}</td>
                              <td>{val?.startDate}</td>
                              <td>{val?.endDate}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>
                  </Col>
                  <Col md={12}>
                      <div className="promtion-list">
                        <h3>Tax</h3>

                        <table className="table  thead-primary w-80">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Amount</th>
                              <th>Tax On</th>
                              <th>applyOn</th>
                            </tr>
                          </thead>
                          <tbody>
                            {outletDetails?.allDetail?.tax?.map((val, i) => (
                              <tr key={i}>
                                <td>{val.title}</td>
                                <td>{val?.amount}</td>
                                <td>{val?.taxOn}</td>
                                <td>{val?.applyOn || "N/A"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="promtion-list">
                        <h3>Timing</h3>

                        <table className="table  thead-primary w-80">
                          <thead>
                            <tr>
                              <th>Day</th>
                              <th>From Time</th>
                              <th>To Time</th>
                              <th>Full Day</th>
                            </tr>
                          </thead>
                          <tbody>
                            {outletDetails?.allDetail?.openingHours?.map(
                              (val, i) => (
                                <tr key={i}>
                                  <td>{val.data || "N/A"}</td>
                                  <td>{val?.fromTime || "N/A"}</td>
                                  <td>{val?.toTime || "N/A"}</td>
                                  <td>{val?.fullDay || "N/A"}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Col>

                </Row>
                <Row>
                  {/* <Col md={6}>
                      <div className="hilight">
                        <h3>Outlet Gallery</h3>

                        {outletPhotos && (
                          <div className="hotel-img">
                            <Gallery
                              photos={outletPhotos}
                              onClick={openDocLightbox}
                            />
                          </div>
                        )}


                        <ModalGateway>
                          {docViewerIsOpen ? (
                            <Modal onClose={closeDocLightbox}>
                              <Carousel
                                currentindex={currentImage}
                                views={outletPhotos.map((x) => ({
                                  ...x,
                                  srcset: x.src,
                                }))}
                              />
                            </Modal>
                          ) : null}
                        </ModalGateway>
                      </div>
                    </Col> */}

                  {/* <Col md={6}>
                      <div className="hilight">
                        <h3>Document Gallery</h3>

                        {documentPhotos && (
                          <div className="hotel-img">
                            <Gallery photos={documentPhotos} onClick={openLightbox} />
                          </div>
                        )}


                        <ModalGateway>
                          {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                              <Carousel
                                currentindex={currentImage}
                                views={documentPhotos.map((x) => ({
                                  ...x,
                                  srcset: x.src,
                                }))}
                              />
                            </Modal>
                          ) : null}
                        </ModalGateway>
                      </div>
                    </Col> */}


                </Row>
              </Container>
            </div>

          </Col>
          <Col md={6}>
            {outletDetails?.bannerImage && (
              <div>
                <section className="slider-section mt-4">

                  <div className="prdouc-img">
                    <img
                      className=""
                      src={outletDetails?.bannerImage}
                      alt="First slide"
                    />
                  </div>


                </section>

                <div className="promtion-list newmanrgin">
                  <h3>Promotion</h3>
                  {/* <ul >
                      {outletDetails?.outletPromotion?.map((val, i) => (
                        <li key={i}>
                          <div className="list-flex">
                            <b>{val.description}</b> <span className="mx-3">Discount: <b>{val?.discount}</b> </span> <span className="mx-3">    From {val?.startDate} to {val?.endDate}</span>

                          </div>
                        </li>
                      ))}

                    </ul> */}
                  {/* <ul>{outletDetails?.outletPromotion?.promotion}</ul> */}

                  <table
                    className="table  thead-primary w-80 mb-5"

                  >
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Discount</th>
                        <th>Price</th>
                        <th>From Date</th>
                        <th>To Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {outletDetails?.outletPromotion?.map((val, i) => (
                        <tr key={i}>
                          <td>{val.description}</td>
                          <td>{val?.discount}</td>
                          <td>{val?.price}</td>
                          <td>{val?.startDate}</td>
                          <td>{val?.endDate}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>


                <div className="hilight mt-5">
                  <h3>Document Gallery</h3>

                  {documentPhotos && (
                    <div className="hotel-img">
                      <Gallery photos={documentPhotos} onClick={openDocLightbox} />
                    </div>
                  )}


                  <ModalGateway>
                    {docViewerIsOpen ? (
                      <Modal onClose={closeDocLightbox}>
                        <Carousel
                          currentindex={currentImage}
                          views={documentPhotos.map((x) => ({
                            ...x,
                            srcset: x.src,
                          }))}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>
                </div>

                <div className="hilight-outlet ">
                  <h3>Outlet Gallery</h3>

                  {outletPhotos && (
                    <div className="hotel-img">
                      <Gallery photos={outletPhotos} onClick={openLightbox} />
                    </div>
                  )}


                  <ModalGateway>
                    {viewerIsOpen ? (
                      <Modal onClose={closeLightbox}>
                        <Carousel
                          currentindex={currentImage}
                          views={outletPhotos.map((x) => ({
                            ...x,
                            srcset: x.src,
                          }))}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>
                </div>




              </div>

            )}
          </Col>
        </Row>

      </div>
    </div>
  )
}

export default SingleOutlet
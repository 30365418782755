import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";

import { IoDocumentsSharp } from "react-icons/io5";

import {
  capitalizeFirstLetter,
  downloadExcel1,
  getPermissionDetails,
} from "../../util/fn";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import useRequest from "../../hooks/useRequest";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AssignVendor = (props) => {
  const { request: requestCities, response: responseCities } = useRequest();
  const { request: request, response: response } = useRequest();
  const { request: requestRole, response: responseRole } = useRequest();
  const { response: responseUpdate, request: requestUpdate } = useRequest();

  const { request: requestUser, response: responseUser } = useRequest();
  const [ids, setIds] = useState([]);
  const [permission, setPermissions] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const location = useLocation();
  const [cities, setCities] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [filterData, setFilterData] = useState({
    cityId: "",
    name: "",
    type: "",
    area: "",
    sort_by: "",
    referralBy: "",
  });
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Restaurent Name" },
    { title: "Type & No." },
    { title: "Registration Date & Time" },
    { title: "Contact Person" },
    { title: "Phone Number / Email" },
    { title: "Referal By" },
    { title: "City" },
    { title: "Reason" },
    { title: "Status" },
    { title: "Registration Score" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [referralBy, setReferalBy] = useState("");
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [area, setArea] = useState("");
  const [staffId, setStatffId] = useState("");
  const [startIndex, setStartIndex] = useState(1);
  const [roleList, setRoleList] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    requestRole("GET", `admin/role/getAllRoles`);
    requestUser("GET", `admin/staff/getAllStaff`);
  }, []);

  useEffect(() => {
    if (responseRole) {
      if (responseRole.status) {
        setRoleList(responseRole.data);
      }
    }
  }, [responseRole]);
  useEffect(() => {
    if (responseUser) {
      if (responseUser.status) {
        setUserList(responseUser.data);
      }
    }
  }, [responseUser]);

  useEffect(() => {
    document.title = "Vendors - Sportyfy";
    requestCities("GET", "admin/vendor/getCity");

    if (props.location.state?.cityId) {
      request(
        "GET",
        `admin/vendor/getStaffVendor?cityId=${props.location.state?.cityId}&name=&type=&page=${page}&per_page=${perPage}&status=${props.location.state?.status}`
      );
      setCity(props.location?.state?.cityId);
    }
  }, []);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
      }
    }
  }, [responseCities]);

  useEffect(() => {
    const savedFilterData = JSON.parse(localStorage.getItem("filterData"));

    if (savedFilterData) {
      // setFilterData(savedFilterData);

      setCity(savedFilterData.cityId || "");
      setName(savedFilterData.name || "");
      setName(savedFilterData.referralBy || "");
      setArea(savedFilterData.area || "");
      setType(savedFilterData.type || "");
      setStatus(savedFilterData.status || "");
      setFilterData({
        ...savedFilterData,
      });
    }
  }, []);

  useEffect(() => {
    if (filterData?.cityId || staffId) {
      handleFilter({ ...filterData, staffId });
    }
  }, [responseCities, staffId]);

  const handleSearch = (e) => {
    e.preventDefault();
    let searchCity = city;
    let searchName = name;
    let searchType = type;
    let searchreferralBy = referralBy;
    const updatedFilterData = { ...filterData };

    if (e.target.name === "name") {
      setName(e.target.value);
      updatedFilterData.name = e.target.value;
      searchName = e.target.value;
    } else if (e.target.name === "area") {
      setArea(e.target.value);
      updatedFilterData.area = e.target.value;
      searchName = e.target.value;
    } else if (e.target.name === "referralBy") {
      setReferalBy(e.target.value);
      updatedFilterData.referralBy = e.target.value;
      searchreferralBy = e.target.value;
    } else if (e.target.name === "type") {
      setType(e.target.value);
      updatedFilterData.type = e.target.value;
      searchType = e.target.value;
    } else if (e.target.name === "sort_by") {
      setType(e.target.value);
      updatedFilterData.sort_by = e.target.value;
      searchType = e.target.value;
    }

    setFilterData(updatedFilterData);

    // Save updated filter data to local storage
    localStorage.setItem("filterData", JSON.stringify(updatedFilterData));
  };

  const handleFilter = ({
    cityId,
    type,
    name,
    area,
    sort_by,
    referralBy,
    startDate,
    endDate,
    staffId,
  }) => {
    let url = `admin/vendor/getStaffVendor?cityId=${cityId}&name=${name}&type=${type}&area=${area}&page=${page}&per_page=${perPage}&sort_by=${sort_by}&status=${status}&referralBy=${
      referralBy ? referralBy : ""
    }&startDate=${startDate ? startDate : ""}&endDate=${
      endDate ? endDate : ""
    }&staffId=${staffId ? staffId : ""}`;
    request("GET", url);
  };

  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
        setTotalDocuments(response.totalDocument);
        setIds(response?.selectedVendors);
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    const city = filterData.cityId;
    request(
      "GET",
      `admin/vendor/getStaffVendor?cityId=${city ? city : ""}&name=${
        name ? name : ""
      }&type=${type ? type : ""}&page=${
        selected + 1
      }&per_page=${perPage}&status=${
        status ? status : ""
      }&referralBy=${referralBy}&startDate=${
        startDate ? startDate : ""
      }&endDate=${endDate ? endDate : ""}`
    );
  };

  useEffect(() => {
    const permissionData = getPermissionDetails(location.pathname, permissions);
    if (permissionData) {
      setPermissions(permissionData);
      if (permissionData?.webAccessEdit) {
        setColumns([...columns]);
      }
    }
  }, []);

  const assignVendor = async () => {
    if (staffId) {
      const data = {
        staffId: staffId,
        vendorId: ids,
      };
      requestUpdate("POST", "admin/staff/assignVendors", data);
    } else {
      toast.error("Please Select Staff Member!");
    }
  };
  useEffect(() => {
    if (responseUpdate) {
      if (responseUpdate.status) {
        toast.success("Vendor Assigned To Member");
        setIds([]);
        setStatffId("");
      }
    }
  }, [responseUpdate]);

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb title={"Vendors"} links={[{ to: "/", name: "Home" }]} />
        </div>
      </div>
      {/* <div className="row"> */}
      <div className="ms-panel">
        <div className="ms-panel-header">
          <div className="row">
            <div className="col-md-1">
              <h6>{"Vendors"}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="cities">City:</label>
                <Select
                  options={
                    cities &&
                    cities.length > 0 &&
                    cities.map((obj) => ({
                      label: obj.name,
                      value: obj._id,
                    }))
                  }
                  name="cities"
                  value={
                    city
                      ? cities &&
                        cities.length > 0 &&
                        cities
                          .filter((item) => item?._id == city)
                          .map((item) => {
                            return { label: item.name, value: item?._id };
                          })[0]
                      : null
                  }
                  onChange={(value) => {
                    setCity(value ? value.value : "");
                  }}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  placeholder="Name"
                  onChange={handleSearch}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="type">Status:</label>
                <select
                  onChange={(e) => {
                    setStatus(e.target.value);
                    const updatedFilterData = {
                      ...filterData,
                      status: e.target.value,
                    };
                    setFilterData(updatedFilterData);

                    localStorage.setItem(
                      "filterData",
                      JSON.stringify({
                        ...filterData,
                        status: e.target.value,
                      })
                    );
                  }}
                  value={status}
                  className="form-control"
                >
                  <option value={""}>Select Status </option>
                  <option value={"accepted"}>Accepted </option>
                  <option value={"rejected"}>Rejected </option>
                  <option value={"pending"}>Pending </option>
                </select>
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center gap-1 mt-4 justify-space-around">
                <div className="form-group p-1">
                  <Button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      const updatedFilterData = {
                        ...filterData,
                        cityId: city,
                      };
                      setFilterData(updatedFilterData);
                      localStorage.setItem(
                        "filterData",
                        JSON.stringify(updatedFilterData)
                      );
                      handleFilter({
                        ...updatedFilterData,
                        startDate,
                        endDate,
                      });
                    }}
                  >
                    Filter
                  </Button>
                </div>
                <div className="form-group">
                  <Button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setFilterData({
                        cityId: "",
                        name: "",
                        status: "",
                        sort_by: "",
                        type: "",
                        area: "",
                      });

                      setCity("");
                      setArea("");
                      setName("");
                      setStatus("");
                      setType("");
                      setReferalBy("");
                      setCities([]);
                      localStorage.setItem(
                        "filterData",
                        JSON.stringify({
                          cityId: "",
                          name: "",
                          status: "",
                          sort_by: "",
                          type: "",
                          area: "",
                          referralBy: "",
                        })
                      );
                      requestCities("GET", "admin/vendor/getCity");
                      handleFilter({
                        cityId: "",
                        name: "",
                        type: "",
                        area: "",
                        type: "",
                        status: "",
                        sort_by: "",
                        referralBy: "",
                      });
                    }}
                  >
                    Clear Filter
                  </Button>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="type"> Role:</label>
                <select className="form-control">
                  <option value={""}>Select Role </option>
                  {roleList &&
                    roleList?.map((val, i) => (
                      <option value={val?.name}>{val?.name} </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="type">Staff:</label>
                <select
                  value={staffId}
                  className="form-control"
                  onChange={(e) => {
                    setStatffId(e.target.value);
                  }}
                >
                  <option value={""}>Select Staff </option>
                  {userList &&
                    userList?.map((val, i) => (
                      <option value={val?._id}>{val?.name} </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="col-md-4 my-4">
              <div className="form-group mx-1 ">
                <Button
                  className="btn btn-primary"
                  type="button"
                  onClick={assignVendor}
                >
                  Assign
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="ms-panel-body">
          <div className="table-responsive">
            <table className="table table-hover thead-primary">
              <thead>
                <tr>
                  {columns &&
                    columns.length > 0 &&
                    columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((item, i) => (
                    <tr key={item.id}>
                      <th scope="row">{i + startIndex}</th>

                      <td>
                        <div className="d-flex gap-3 align-items-center justify-content-between">
                          <div>
                            <p>{item.name}</p>
                          </div>
                          <div>
                            <input
                              value={""}
                              type="checkbox"
                              checked={ids?.includes(item?.id)}
                              onChange={() => {
                                if (ids?.includes(item?.id)) {
                                  setIds((pre) =>
                                    pre.filter((val) => val !== item?.id)
                                  );
                                } else {
                                  setIds((pre) => [...pre, item.id]);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        {item.vendorType
                          ? capitalizeFirstLetter(item.vendorType)
                          : ""}
                      </td>
                      <td>{item.date}</td>
                      <td>{item.contractPerson}</td>
                      <td>{item?.phone + " / " + item?.email}</td>
                      <td>{item?.referralBy}</td>
                      <td>
                        {
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${item.id}`}>
                                {item.address}
                              </Tooltip>
                            }
                          >
                            <span>
                              {item.city}
                              <i
                                title="View"
                                className="flaticon-information ml-3 mt-1"
                              ></i>
                            </span>
                          </OverlayTrigger>
                        }
                      </td>
                      {/* <td>{item.address}</td> */}
                      <td>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${item.id}`}>
                              {item.reason}
                            </Tooltip>
                          }
                        >
                          <span>
                            <i
                              title="View"
                              className="flaticon-information"
                            ></i>
                          </span>
                        </OverlayTrigger>
                      </td>
                      <td>
                        {item.status == "accepted" ? (
                          <span className="badge badge-success">
                            {capitalizeFirstLetter(item.status)}
                          </span>
                        ) : item.status == "rejected" ? (
                          <span className="badge badge-danger">
                            {capitalizeFirstLetter(item.status)}
                          </span>
                        ) : item.status == "pending" ? (
                          <span className="badge badge-primary">
                            {capitalizeFirstLetter(item.status)}
                          </span>
                        ) : (
                          ""
                        )}
                      </td>

                      <td>{item.registrationScore}</td>
                      {/* <td>{item.discount}</td>
                          <td>{item.commission}</td>
                          <td>{item.rewardPartner}</td>
                          <td>{item.cityRank}</td>
                          <td>{item.starRank}</td> */}
                    </tr>
                  ))}
                {data.length == 0 && (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      {" "}
                      No vendor has found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        {perPage !== 0 &&
          data.length != totalDocuments &&
          data.length !== 0 && (
            <Pagination
              page={page}
              totalDocuments={totalDocuments}
              getNewData={fetchMoreData}
              perPage={perPage}
              currentDocLength={data.length}
            />
          )}
      </div>
    </div>
  );
};

export default AssignVendor;

import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";

import { Button } from "react-bootstrap";
import useRequest from "../../../hooks/useRequest";
import { toast } from "react-toastify";

import { MdDelete, MdEdit } from "react-icons/md";
import Swal from "sweetalert2";
import Pagination from "../../Pagination/Pagination";
import AddCusine from "./AddCusine";

function CusineList() {
  const { response: responseUpdate, request: requestUpdate } = useRequest();
  const { response: responseDelete, request: requestDelete } = useRequest();
  const { response: responseListResponse, request: requestList } = useRequest();
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [columns] = useState([
    { title: "S.No." },
    { title: "Title" },
    { title: "Image" },
    { title: "Category Type" },
    { title: "Category" },
    { title: "Status" },
    { title: "Action" },
  ]);
  const [data, setData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [EditData, setEditData] = useState({
    categoryType: "",
    title: "",
    categoryId: "",
    status: "",
    image: "",
  });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setEditData("");
    setShowModal(false);
  };

  const handleSaveChanges = (data) => {
    requestUpdate("POST", "admin/dishcuisin/addData", data);
    setEditData("");
  };

  const handleUpdate = (data) => {
    requestUpdate("POST", "admin/dishcuisin/updateData", data);
    setEditData("");
  };

  useEffect(() => {
    requestList("POST", `admin/dishcuisin/getAllData`, { page, per_page: 10 });
  }, [page]);

  useEffect(() => {
    if (responseUpdate) {
      if (responseUpdate.status) {
        toast.success("Data has been added successfully.");
      }
      requestList("POST", `admin/dishcuisin/getAllData`, {
        page,
        per_page: 10,
      });

      handleCloseModal();
    }
  }, [responseUpdate]);

  useEffect(() => {
    if (responseListResponse) {
      if (responseListResponse.status) {
        setData(responseListResponse.data);
        setTotalDocuments(responseListResponse.totalDocument);
      }
    }
  }, [responseListResponse]);

  useEffect(() => {
    if (responseDelete) {
      if (responseDelete.status) {
        toast.success("Data has been deleted successfully.");
        requestList("POST", `admin/dishcuisin/getAllData`, {
          page,
          per_page: 10,
        });
      }
      handleCloseModal();
    }
  }, [responseDelete]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this banner?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(function (result) {
      if (result.value) {
        requestDelete("POST", "admin/dishcuisin/removeData", { dataId: id });
      }
    });
  };

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Dish Cuisine"}
            links={[{ to: "/", name: "Home" }]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-1">
                  <h6>{"DishCuisine"}</h6>
                </div>
                <div className="col-md-9"></div>
                <div className="col-md-2">
                  <div className="form-group">
                    <Button
                      className="btn btn-primary"
                      onClick={handleShowModal}
                    >
                      {" "}
                      Add
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={i}>
                          <th scope="row">{i + 1}</th>
                          <td>{item?.title || "N/A"}</td>
                          <td>{item?.categoryType || "N/A"}</td>
                          <td>
                            <img src={item?.image} alt="image" />
                          </td>
                          <td>{item?.categoryId?.name || "N/A"}</td>
                          <td>{item?.status || "N/A"}</td>
                          <td>
                            <div className="d-flex">
                              <div className="">
                                <span className="cursor-pointer">
                                  <MdEdit
                                    fontSize={"20px"}
                                    onClick={() => {
                                      handleShowModal();
                                      setEditData(item);
                                    }}
                                  />
                                </span>
                              </div>
                              <div className="">
                                <span className="cursor-pointer">
                                  <MdDelete
                                    fontSize={"20px"}
                                    onClick={() => handleDelete(item?._id)}
                                  />
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          {" "}
                          No data found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {data && (
                  <Pagination
                    page={page}
                    totalDocuments={Math.ceil(totalDocuments / 10)}
                    getNewData={fetchMoreData}
                    perPage={10}
                    currentDocLength={data.length}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddCusine
        show={showModal}
        handleClose={handleCloseModal}
        handleSave={handleSaveChanges}
        handleUpdate={handleUpdate}
        initialData={EditData}
      />
    </div>
  );
}

export default CusineList;

import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import useRequest from "../../../hooks/useRequest";

const AddCusine = ({
  show,
  handleClose,
  handleSave,
  handleUpdate,
  initialData,
}) => {
  const { request: request, response: response } = useRequest();
  const [data, setData] = useState([]);
  const [imagesa, setImage] = useState('');

  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
      }
    }
  }, [response]);

  const formik = useFormik({
    initialValues: {
      categoryType: initialData?.categoryType || "",
      title: initialData?.title || "",
      categoryId: initialData?.categoryId?._id || "",
      status: initialData?.status || "",
      image: initialData?.image?.[0] || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      categoryType: Yup.string().required("Type is required"),
      title: Yup.string().required("Title is required"),
      categoryId: Yup.string().required("Category is required"),
      status: Yup.string().required("Status is required"),
      image: initialData?._id
        ? Yup.string()
        : Yup.string().required("Image is required"),
    }),
    onSubmit: (values) => {
      if (initialData?._id) {
        const formData = new FormData();
        formData.append("dataId", initialData?._id);
        if (imagesa) {
          formData.append("image", imagesa);
        }
        for (const key in values) {
          if (values.hasOwnProperty(key) && key !== "image") {
            if (values[key] instanceof File || values[key] instanceof Blob) {
              formData.append(key, values[key]);
            } else {
              formData.append(key, values[key]);
            }
          }
        }
        handleUpdate(formData);
      } else {
        const formData = new FormData();
        for (const key in values) {
          if (values.hasOwnProperty(key)) {
            if (values[key] instanceof File || values[key] instanceof Blob) {
              formData.append(key, values[key]);
            } else {
              formData.append(key, values[key]);
            }
          }
        }
        handleSave(formData);
      }
    },
  });
  useEffect(() => {
    request("POST", "manageCategory/getAllCategory", {
      type: initialData?.categoryType
        ? initialData?.categoryType
        : formik.values.categoryType,
      page: 1,
      per_page: 100,
    });
  }, [formik.values.categoryType]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Dish Cusine</Modal.Title>
        <span onClick={handleClose} style={{ cursor: "pointer" }}>
          X
        </span>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group controlId="formAmount">
            <Form.Label>Type</Form.Label>
            <select
              name="categoryType"
              onChange={formik.handleChange}
              value={formik.values.categoryType}
              className={`form-control ${
                formik.touched.categoryType && formik.errors.categoryType
                  ? "is-invalid"
                  : ""
              }`}
            >
              <option value="" disabled>
                Select Type
              </option>
              <option value="dish">Dish</option>
              <option value="cuisine">Cuisine</option>
            </select>
            <div className="color-red">
              {formik.touched.categoryType && formik.errors.categoryType}
            </div>
          </Form.Group>

          <Form.Group controlId="formAmount">
            <Form.Label>Category</Form.Label>
            <select
              name="categoryId"
              onChange={formik.handleChange}
              value={formik.values.categoryId}
              className={`form-control ${
                formik.touched.categoryId && formik.errors.categoryId
                  ? "is-invalid"
                  : ""
              }`}
            >
              <option value="" disabled>
                Select category
              </option>
              {data &&
                data?.map((val, i) => (
                  <option value={val?._id} key={i}>
                    {val?.name}
                  </option>
                ))}
            </select>
            <div className="color-red">
              {formik.touched.categoryId && formik.errors.categoryId}
            </div>
          </Form.Group>

          <Form.Group controlId="formType">
            <Form.Label>Title</Form.Label>
            <input
              type="text"
              name="title"
              onChange={formik.handleChange}
              value={formik.values.title}
              className={`form-control ${
                formik.touched.title && formik.errors.title ? "is-invalid" : ""
              }`}
            />
            <div className="color-red">
              {formik.touched.title && formik.errors.title}
            </div>
          </Form.Group>

          <Form.Group controlId="formType">
            <Form.Label>Image</Form.Label>
            <input
              type="file"
              name="image"
              onChange={(e) => {
                const file = e.target.files[0];
                formik.setFieldValue("image", file);
                setImage(file);
              }}
              className={`form-control ${
                formik.touched.image && formik.errors.image ? "is-invalid" : ""
              }`}
            />
            <div className="color-red">
              {formik.touched.image && formik.errors.image}
            </div>
          </Form.Group>

          <Form.Group controlId="formType">
            <Form.Label>Status</Form.Label>
            <select
              name="status"
              onChange={formik.handleChange}
              value={formik.values.status}
              className={`form-control ${
                formik.touched.status && formik.errors.status
                  ? "is-invalid"
                  : ""
              }`}
            >
              <option value="" disabled>
                Select Status
              </option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
            <div className="color-red">
              {formik.touched.status && formik.errors.status}
            </div>
          </Form.Group>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCusine;
